import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
//styles
import styles from './locationSearch.module.scss';
import classNames from 'classnames';
//Images
import Image from 'next/image';
import Spinner from './spinner';
import SuggestionItem from './SuggestionItem';
import { MapMySearchType } from '@/apis/MapMySearch';
import { mapMyReverseGeo, MapMyRevGeoType } from '@/apis/mapMyReverseGeo';
import { ExplorepageType } from '../exploreComponent';
import { getOperatedCities, OperatedCitiesType } from '@/apis/getOperatedCities';

declare global {
  interface Window {
    mappls: any;
  }
}

interface LocationSearchPropType {
  type: ExplorepageType;
}

const LocationSearch = ({ type }: LocationSearchPropType) => {
  const router = useRouter();

  const [spinner, setSpinner] = useState<boolean>(false);
  const [cities, setCities] = useState<OperatedCitiesType[]>([])

  const getCities = useCallback(async () => {
    try {
      const res = await getOperatedCities();
      setCities(res);
    } catch (e) {
      console.error('getCities error', e);
    }
  }, [])

  useEffect(() => {
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handle = useCallback(
    (location: MapMySearchType | MapMyRevGeoType) => {
      if (type == ExplorepageType.VENUES) {
        if (location?.locality && location?.city) {
          router.push(`${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/${location?.locality.toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`);
        } else if (location?.city) {
          router.push(`${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`);
        }
      } else if (type == ExplorepageType.GAMES) {
        if (location?.locality && location?.city) {
          router.push(`game-list/${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/${location?.locality.toLocaleLowerCase().replaceAll(' ', '-')}`);
        } else if (location?.city) {
          router.push(`game-list/${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`);
        }
      }
    },
    [router, type]
  );

  const getCity = useCallback(async (lat: number, lon: number) => {
    try {
      const res = await mapMyReverseGeo(lat, lon);
      if (res?.length > 0) {
        handle(res[0]);
      }

      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.error('search error', e);
    }
  }, [handle]);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      setSpinner(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getCity(position?.coords?.latitude, position?.coords?.longitude);

        },
        (error) => {
          setSpinner(false);
          if (error.code === 1) {
            alert('User denied geolocation access.');
          } else {
            alert('Error getting geolocation.');
          }
        }, {
        enableHighAccuracy: false,
      }
      );
    } else {
      setSpinner(false);
      alert('Geolocation is not supported.');
    }
  }, [getCity]);


  return (
    <>
      <div className={classNames(styles['search_wrapper'], styles['mb-52'])}>
        <div className={classNames(styles.gps_alignment)}>
          {spinner ? (
            <Spinner />
          ) : (
            <>
              <Image className={styles['mr-12']} height={20} width={20} src={'/svgs/green_gps.svg'} alt='gps' />
              <div
                onClick={getLocation}
                className={classNames(
                  styles.locateme,
                  styles['cursor-pointer'],
                  styles['mr-12']
                )}
              >
                Use Current Location
              </div>
              <Image width={16} height={13} src={'/svgs/right_arrow_2.svg'} alt='gps' />
            </>
          )}
        </div>

      </div>
      {cities?.length > 0 && <div className={classNames(styles[''])}>
        <p className={classNames(styles['mb-24'], styles['button_2'], styles['grey4'])}>CITIES WE OPERATE IN</p>
        <div className={classNames(styles['row'], styles['align-center'], styles['wrap'], styles['width_90'])}>
          {cities?.map((item, index) => {
            return (
              <SuggestionItem key={index} title={item?.city} onClick={() => { }} type={type} />
            )
          })}
        </div>
      </div>}

    </>
  );
};



export default LocationSearch;
