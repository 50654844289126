import React, { useCallback, useState, useEffect } from 'react'
import styles from '../exploreComponent.module.scss'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import SuggestionItem from '../locationSearch/SuggestionItem'
import { ExplorepageType } from '../exploreComponent'
import { getOperatedCities, OperatedCitiesType } from '@/apis/getOperatedCities'

interface PopularCitiesPropType {
    type: ExplorepageType;
    onSelect?: () => void;
}
const PopularCities = ({ onSelect, type }: PopularCitiesPropType) => {
    const router = useRouter();
    const [cities, setCities] = useState<OperatedCitiesType[]>([])

    const getCities = useCallback(async () => {
        try {
            const res = await getOperatedCities();
            setCities(res);
        } catch (e) {
            console.error('getCities error', e);
        }
    }, [])

    useEffect(() => {
        getCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = useCallback(
        (location: string) => {

            if (location) {
                if (type == ExplorepageType.VENUES) {
                    router.push(`/${location}/sports-venues`).then(() => {
                        router.reload()
                    });
                } else {
                    router.push(`/game-list/${location}`).then(() => {
                        router.reload()
                    });
                }

                onSelect && onSelect();
            }
        },
        [onSelect, router, type]
    );

    return (
        <div className={classNames(styles['column'])}>
            <p className={classNames(styles['sub_6'], styles['white1'], styles['mv1'], styles['desktop'])}>Popular Cities</p>
            <div className={classNames(styles['row'], styles['align-center'], styles['wrap'], styles['width_100'])}>
                {cities?.map((item, index) => {
                    return (
                        <SuggestionItem key={index} title={item?.city} onClick={() => { }} type={type} />
                    )
                })}
            </div>
        </div>
    )
}

export default PopularCities